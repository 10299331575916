import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, storage } from "../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { userService } from "./user.service";
import { json } from "react-router-dom";
import CryptoJS from "crypto-js";

export const companyService = {
  getCompany,
  getCompanyID,
  updateCompany,
  uploadFile,
  createCompany,
  updatedCompany,
  updateProtocolPattern,
  getProtocolPattern,
  smtpTest,
};
async function createCompany(company) {
  try {
    const docRef = doc(db, "xc_company", company.id);
    await setDoc(docRef, {
      ...company,
      company_agb_updatedAt: serverTimestamp(),
      company_registration: serverTimestamp(),
      company_profile_updatedAt: serverTimestamp(),
    });
  } catch (error) {
    // Handle errors gracefully
    console.error("Error creating company:", error);
  }
}

async function updatedCompany(company) {
  const docRef = doc(db, "xc_company", company.id);
  await setDoc(docRef, {
    ...company,
    company_profile_updatedAt: serverTimestamp(),
  });
}

// Function to encrypt a password
const encryptPassword = async (password, key) => {
  const encryptedPassword = CryptoJS.AES.encrypt(password, key).toString();
  return encryptedPassword;
};

const decryptPassword = async (encryptedPassword, key) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedPassword, key);
    if (bytes.sigBytes > 0) {
      return bytes.toString(CryptoJS.enc.Utf8);
    } else {
      // Handle decryption errors, such as invalid key or ciphertext
      return "Decryption failed";
    }
  } catch (error) {
    // Handle decryption errors
    return "Decryption error";
  }
};

async function updateCompany(company) {
  // Encrypt the password
  if (company.smtp?.password !== "" && company.smtp?.password !== undefined) {
    const hashedPassword = await encryptPassword(
      company.smtp.password,
      company.smtp.key
    );
    company.smtp.password = hashedPassword;
  }
  const docRef = doc(db, "xc_company", company.id);
  return await updateDoc(docRef, company);
}

async function getCompany(company_id) {
  const docRef = doc(db, "xc_company", company_id);
  const docSnap = await getDoc(docRef);
  var result = docSnap.data();
  if (result?.smtp) {
    result.smtp.password = await decryptPassword(
      result.smtp.password,
      result.smtp.key
    );
  }
  return result;
}
async function getCompanyID(db_name, ID) {
  try {
    const docRef = doc(db, db_name, ID);
    const company = await getDoc(docRef);
    if (company.exists()) {
      return company.data().company_id;
    }
  } catch (error) {
    console.log(error);
  }
}

async function uploadFile(file, imgIsLogo, company) {
  const name = new Date().getTime() + file.name;
  const storageRef = imgIsLogo
    ? ref(
        storage,
        "profil/logo_" + company.id + "_" + userService.getId() + ".jpeg"
      )
    : ref(
        storage,
        "profil/stamp_" + company.id + "_" + userService.getId() + ".jpeg"
      );
  const uploadTask = uploadBytesResumable(storageRef, file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
        reject(error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          if (imgIsLogo) {
            await updateCompany({
              id: company.id,
              company_logo: downloadURL,
              company_profile_updatedAt: serverTimestamp(),
            });
          } else {
            await updateCompany({
              id: company.id,
              company_stempel_url: downloadURL,
              company_profile_updatedAt: serverTimestamp(),
            });
          }
          resolve({ progress: 100, downloadURL });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      }
    );
  });
}

async function updateProtocolPattern(
  company_id,
  protocol_pattern,
  draft_block_pattern
) {
  try {
    const docRef = doc(db, "xc_company", company_id);
    return await updateDoc(docRef, {
      protocol_pattern: protocol_pattern,
      draft_block_pattern: draft_block_pattern,
    });
  } catch (error) {
    console.error("Error updating field: ", error);
  }
}

async function getProtocolPattern(companyId) {
  try {
    var protocolPattern = [];
    var draftBlockPattern = [];
    const docRef = doc(db, "xc_company", companyId);
    const docSnap = await getDoc(docRef);
    protocolPattern = docSnap.data().protocol_pattern;
    draftBlockPattern = docSnap.data().draft_block_pattern;

    return { protocolPattern, draftBlockPattern };
  } catch (error) {
    console.error("Error getting document:", error);
    return null;
  }
}

async function smtpTest(smtpConfig) {
  // Create an AbortController instance
  const controller = new AbortController();
  const signal = controller.signal;

  // Set a timeout for the request
  const timeoutId = setTimeout(() => {
    // Abort the fetch request if it takes too long
    controller.abort();
  }, 5000); // Timeout after 5 seconds

  try {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ smtpConfig: smtpConfig }),
      headers: {
        "Content-type": "application/json",
      },
    };

    // Perform the fetch request
    const response = await fetch(
      `https://dreamy-wright.81-169-129-111.plesk.page:5000/api/company/test`,
      { ...requestOptions, signal }
    );

    // Clear the timeout if the request completes successfully
    clearTimeout(timeoutId);

    // Handle the response
    return handleResponse(response);
  } catch (error) {
    // Check if the error was caused by the request being aborted
    if (error.name === "AbortError") {
      return { success: false, message: "Failed to send test email." };
    }
    // Rethrow the error if it's not related to the timeout
    throw error;
  }
}

// Example usage:
// smtpTest(yourSmtpConfig).catch(error => console.error(error));

// handle response
function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
