/**
 * Router service file
*/
import Dashboard from 'routes/Dashboard'
import Properties from 'routes/Properties'
import Protokol from 'routes/Protokol'
import TarifCalculator from 'routes/Tarif_Calculator'
import Kaution from 'routes/Kaution_Contract'
import Contract from 'routes/Contract'
import Legal from 'routes/LegalInfo'
import Support from 'routes/Support'
import ProtocolPattern from 'routes/protocol_pattern'
import User from 'routes/User'
import Company from 'routes/company'
import Smtp from 'routes/SMTP'
import Administration from 'routes/Administration'
import ImportProducts from 'routes/Import_Products'
import Dump from 'routes/Dump_DB'
import KautionSettings from 'routes/KautionSettings'
import Notification from 'routes/Notifications'
import Protocol from 'routes/Dashboard/ProtocolList'
import ContractList from 'routes/Dashboard/ContractList'
import KautionList from 'routes/Dashboard/KautionList'
import ArchiveList from 'routes/Dashboard/ArchiveList'
import Dsl from 'routes/LegalInfo/dsl-page'
import OverviewPromoCode from 'routes/promo-code/redirection'
import ProtokollOvwerView from 'routes/Dashboard/protocol_overview'
import ProtocolControl from 'routes/Dashboard/ProtocolControl'
import ContractOverview from 'routes/Dashboard/Showproduct'
import ShowKaution from 'routes/Kaution_Contract/showKaution'
import DeffectOverview from 'routes/DeffectFilter'





export default [
   
	{
		path: 'dashboard',
		component: Dashboard
	},
	{
		path: 'overview',
		component: Dashboard
	},
	{
		path: 'archiv',
		component: Dashboard
	},
	{
		path: 'realestate',
		component: Properties
	},
	{
		path: 'tarif-calculator',
		component: TarifCalculator
	},
	{
		path: 'protocol',
		component: Protokol
	},
	{
		path: 'protocol-overview',
		component: ProtokollOvwerView
	},
	{
		path: 'deffects',
		component: DeffectOverview
	},
	{
		path: 'contract-overview',
		component: ContractOverview
	},
	{
		path: 'kaution-overview',
		component: ShowKaution
	},
	{
		path: 'show_protocolcontrol',
		component: ProtocolControl
	},
	{
		path: 'update_protocolcontrol',
		component: ProtocolControl
	},
	{
		path: 'protocolcontrol',
		component: ProtocolControl
	},
	{
		path: 'kaution',
		component: Kaution
	},
	{
		path: 'kaution-settings',
		component: KautionSettings
	},
	{
		path: 'contract/selectProduct',
		component: Contract
	},
	{
		path: 'legal',
		component: Legal
	},
	{
		path: 'support',
		component: Support
	},
	{
		path: 'protocol-pattern',
		component: ProtocolPattern
	},
	{
		path: 'user',
		component: User
	},
	{
		path: 'company',
		component: Company
	},
	{
		path: 'smtp',
		component: Smtp
	},
	{
		path: 'administration',
		component: Administration
	},
	{
		path: 'import',
		component: ImportProducts
	},
	{
		path: 'dump',
		component: Dump
	},
	{
		path: 'notifikation',
		component: Notification
	},{
		path:'protocol/list',
		component:Protocol
	},{
		path:'contrat/list',
		component:ContractList
	},{
		path:'kautions/list',
		component:KautionList
	},{
		path:'archive/list',
		component:ArchiveList
	 },{
	 	path:'promocode/overview',
	 	component:OverviewPromoCode
	 }
	 ,{
		path:'dsl',
		component:Dsl
	}


	


]