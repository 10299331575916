/**
 * Courses Routing File
 */

import Store from 'Stored';
import React from 'react'
import { FirebaseContextProvider } from "../../context/firebaseContext";
import { Route, Switch } from 'react-router-dom'
import {
	AsyncDeffectListComponent,
	AsyncDeffectDetailsComponent
} from 'routes';

const DeffectDashboard = ({ match }) => (
	<Switch>
		<Store>
			<FirebaseContextProvider>
				<Route
					path={`${match.url}/overview`}
					component={AsyncDeffectListComponent}
				>

				</Route>
				<Route
					path={`${match.url}/details`}
					component={AsyncDeffectDetailsComponent}
				>

				</Route>
			</FirebaseContextProvider>
		</Store>

	</Switch>
)
export default DeffectDashboard;